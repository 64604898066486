import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {
  confirmConfig: {},
  isOpen: false,
  callbackParams: {},
  callback: null,
  confirmed: false,
  speechAudio: null,
  filename: null
}

export const confirmSlice = createSlice({
  name: 'confirm',
  initialState,
  reducers: {
    openConfirm: (state, action) => {
      state.confirmConfig = {
        ...action.payload.confirmConfig
      }

      state.isOpen = true

      state.callback = action.payload.callback

      state.callbackParams = action.payload.callbackParams || {}

      state.speechAudio = action.payload.speechAudio || null

      state.filename = action.payload.filename || null
    },
    closeConfirm: (state) => {
      state.isOpen = false
    },
    setConfirmed: (state, action) => {
      state.confirmed = action.payload
    }
  }
})

export const handleConfirm = createAsyncThunk('confirm/handleConfirm', async (_, thunkAPI) => {
  thunkAPI.dispatch(closeConfirm())
  thunkAPI.dispatch(setConfirmed(true))
})

export const handleCancel = createAsyncThunk('confirm/handleCancel', async (_, thunkAPI) => {
  thunkAPI.dispatch(closeConfirm())
  thunkAPI.dispatch(setConfirmed(false))
})

export const { openConfirm, closeConfirm, setConfirmed } = confirmSlice.actions

export default confirmSlice.reducer
