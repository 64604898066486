import PropTypes from 'prop-types'
// @mui
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
// hooks
import { useResponsive } from 'src/hooks/use-responsive'
// components
import Logo from 'src/components/logo'

export default function LoginLayout({ children }) {
  const mdUp = useResponsive('up', 'md')

  return (
    <Stack component='main' direction='row' sx={{ minHeight: '100vh' }}>
      {mdUp && (
        <>
          <Logo
            sx={{
              zIndex: 9,
              position: 'absolute',
              bottom: 80,
              width: 70,
              height: 70,
              left: { xs: 20, md: 50 }
            }}
          />
          <Typography
            variant='overline'
            sx={{
              fontSize: 22,
              zIndex: 9,
              position: 'absolute',
              bottom: 55,
              color: 'white',
              letterSpacing: '3px',
              left: { xs: 20, md: 50 }
            }}
          >
            E-DUTY
          </Typography>
          <Box
            sx={{
              width: '100vw',
              height: '100vh',
              background:
                'linear-gradient(180deg, rgba(6, 102, 169, 0.00) 0%, #212B36 80.78%, #212B36 100%)'
            }}
          >
            <img
              src='/assets/illustrations/bg-login.jpeg'
              alt='auth'
              style={{
                objectFit: 'cover',
                height: '100%',
                width: '100%',
                zIndex: '-1',
                position: 'relative'
              }}
            />
          </Box>
        </>
      )}

      <Stack
        sx={{
          width: 1,
          mx: 'auto',
          maxWidth: 450,
          alignItems: 'center',
          justifyContent: 'center',
          px: 5
        }}
      >
        {children}
      </Stack>
    </Stack>
  )
}

LoginLayout.propTypes = {
  children: PropTypes.node,
}
