// @mui
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { useDispatch, useSelector } from 'react-redux'
import { handleConfirm, handleCancel } from 'src/reducers/confirm'

// ----------------------------------------------------------------------

export default function Confirm() {
  const confirmConfig = useSelector((state) => state.confirm.confirmConfig)

  const isOpen = useSelector((state) => state.confirm.isOpen)

  const speechAudio = useSelector((state) => state.confirm.speechAudio)

  const dispatch = useDispatch()

  const onClose = () => {
    dispatch(handleCancel())
  }

  const onConfirm = () => {
    dispatch(handleConfirm())
  }

  return (
    <Dialog fullWidth maxWidth='xs' open={isOpen}>
      <DialogTitle sx={{ pb: 2 }}>{confirmConfig.title}</DialogTitle>

      {confirmConfig.message && (
        <DialogContent sx={{ typography: 'body2' }}> {confirmConfig.message} </DialogContent>
      )}

      {speechAudio && (
        <audio
          controls
          style={{ marginTop: '5px', borderRadius: 16, marginLeft: '15px', marginRight: '15px' }}
        >
          <source
            src={`data:${speechAudio.type};base64,${speechAudio.base64}`}
            type={speechAudio.type}
          />
          <track kind='captions' srcLang='it' label='Italiano' />
          Il tuo browser non supporta gli audio.
        </audio>
      )}

      <DialogActions>
        <Button variant='outlined' color='inherit' onClick={onClose}>
          {confirmConfig.ko}
        </Button>

        <Button variant='contained' color={confirmConfig.color || 'error'} onClick={onConfirm}>
          {confirmConfig.ok}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
