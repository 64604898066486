import PropTypes from 'prop-types'
import { forwardRef } from 'react'
// @mui
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
// routes
import { RouterLink } from 'src/routes/components'
import { paths } from 'src/routes/paths'

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const logo = (
    <Box
      ref={ref}
      component='div'
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        backgroundSize: 'cover',
        ...sx
      }}
      {...other}
    >
      <img src='/logo/eduty_logo.png' alt='eduty logo' />
    </Box>
  )

  if (disabledLink) {
    return logo
  }

  return (
    <Link component={RouterLink} href={paths.homepage} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  )
})

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object
}

export default Logo
