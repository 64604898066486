// @mui
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

// components
import { useTheme } from '@emotion/react'

// ----------------------------------------------------------------------

export default function Footer() {
  const theme = useTheme()

  return (
    <Box
      component='footer'
      sx={{
        py: 1,
        px: 4,
        position: 'relative',
        bgcolor: 'background.default',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        borderTop: `dashed 1px ${theme.palette.divider}`
      }}
    >
      <Typography variant='caption' component='div'>
        {new Date().getFullYear()} © E-DUTY by
        <Link sx={{ mx: 0.5 }} href='https://www.inera.it/' target='_blank'>
          Inera srl
        </Link>
        - v1.0.0
      </Typography>
    </Box>
  )
}
